export let CORE_PLATFORM_URL = 'https://api.5cnetwork.com'
export let AI_SERVICE_URL = 'https://ai-service.5cnetwork.com/ai'

export const AUTH_TOKEN = 'NWNuZXR3b3JrOjVjbmV0d29yaw=='

export const OTHERS_SKIP_REASON = 31

if (process.env.REACT_APP_NODE_ENV === 'staging') {
  CORE_PLATFORM_URL = 'https://e2e-staging-api.5cnetwork.com'
  AI_SERVICE_URL = 'https://e2e-staging-api.5cnetwork.com/ai'
}

if (process.env.REACT_APP_NODE_ENV === 'qa') {
  CORE_PLATFORM_URL = 'https://e2e-qa-api.5cnetwork.com'
  AI_SERVICE_URL = 'https://e2e-qa-api.5cnetwork.com/ai'
}

if (process.env.REACT_APP_NODE_ENV === 'sandbox') {
  CORE_PLATFORM_URL = 'https://e2e-sandbox-api.5cnetwork.com'
  AI_SERVICE_URL = 'https://e2e-sandbox-api.5cnetwork.com/ai'
}

export enum ModStudy {
  CHEST = 'CHEST',
  SPINE = 'SPINE',
  KNEE = 'KNEE',
  SHOULDER = 'SHOULDER',
  ELBOW = 'ELBOW',
  KUB = 'KUB',
  LEG = 'LEG',
  THIGH = 'THIGH',
  ANKLE = 'ANKLE',
  FOOT = 'FOOT',
  HEEL = 'HEEL',
  ARM = 'ARM',
  FOREAREM = 'FOREARM',
  WRIST = 'WRIST',
  HAND = 'HAND',
  HIP = 'HIP',
  ADBOMEN = 'ABDOMEN',
  PELVIS = 'PELVIS',
}

export enum StudyCountStatuses {
  REPORTED = 'Reported',
  SKIPPED = 'Skipped',
  ACTIVE = 'Active',
}

export const ModStudyMap = {
  [ModStudy.CHEST]: [16],
  [ModStudy.KNEE]: [35],
  [ModStudy.SPINE]: [19, 20, 21, 22],
  [ModStudy.SHOULDER]: [28],
  [ModStudy.ELBOW]: [30],
  [ModStudy.KUB]: [25],
  [ModStudy.LEG]: [36],
  [ModStudy.THIGH]: [34],
  [ModStudy.ANKLE]: [37],
  [ModStudy.FOOT]: [39],
  [ModStudy.HEEL]: [40],
  [ModStudy.FOREAREM]: [31],
  [ModStudy.HAND]: [33],
  [ModStudy.WRIST]: [32],
  [ModStudy.ARM]: [29],
  [ModStudy.PELVIS]: [26],
  [ModStudy.ADBOMEN]: [24],
  [ModStudy.HIP]: [27],
}

export enum VariablesSelectionType {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  TEXT_INPUT = 'TEXT_INPUT',
}

export const OSTEOCHECK_MOD_STUDY = 472
export const TB_SCREENING_MOD_STUDY = 479
export const XRAY_BIONIC = 482
export const XRAY_STUDY = 481
export const CHEST_MOD_STUDY = 16
export const NON_SKIPPABLE_CLIENT_IDS = [3754]

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export enum TextFonts {
  ProductSans = 'ProductSans',
  Roboto = 'Roboto',
}

export enum TextSizes {
  xs = '0.5rem',
  s = '0.75rem',
  sp = '0.875rem',
  m = '1rem',
  mp = '1.125rem',
  l = '1.25rem',
  lp = '1.5rem',
  xl = '2rem',
  xxl = '2.5rem',
  xxxl = '3rem',
}

export enum LetterSpacing {
  xs = '0px',
  xsp = '0.1px',
  s = '0.25px',
  m = '0.5px',
  l = '0.75px',
  xl = '1px',
  xxl = '1.25px',
  xxxl = '1.5px',
  xxxxl = '2.5px',
}

export enum TextFontWeights {
  Bold = 700,
  Normal = 400,
}

export enum TextCases {
  INITIAL = 'initial',
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  INHERIT = 'inherit',
  REVERT = 'revert',
  UNSET = 'unset',
  NONE = 'none',
  CAPITALIZE = 'capitalize',
}

export enum TextColors {
  appBackground = '#FCFAFA',
  primary = '#010529',
  secondary = '#FFFFFF',
  secondaryDark = '#F6F7F9',
  secondaryDarker = '#F5F5F5',
  ternary = '#707070',
  ternaryDark = '#2C2B2B',
  ternaryLight = '#0010D9',
  accent = '#FFF500',
  primaryLight = '#202343',
  timeRegular = '#0FC1C7',
  timeCritical = '#EE6958',
  caseRegular = '#FFFFFF',
  caseCritical = '#FAEFF3',
  favourite = '#E6789B',
  success = '#00C69D',
  successLight = '#E1FFE6',
  danger = '#C64E59',
  dangerLight = '#fecfc4',
  dull = '#C7C5C5',
  highlight1 = '#E6789B',
  highlight2 = '#03E2B4',
  highlight3 = '#031265',
  highlight4 = '#21A5FF',
  highlight5 = '#FCFAB4',
  highlight6 = '#F4B45D',
  highlight7 = '#F8F8F8',
  highlight8 = '#0066CC',
  highlight9 = '#E5E8FF91',
  highlight10 = '#008B80',
  highlight11 = '#FDD835',
  highlight12 = '#0088FE',
  highlight13 = '#00C49F',
  calSelectedDay = '#C3C7DB',
  progressState1 = '#EE6958',
  progressState2 = '#F4B45D',
  progressState3 = '#FFEB00',
  progressState4 = '#0FC1C7',
  transparent = 'transparent',
  callActive = '#F1FFFC',
  callInactive = '#FEEAE8',
  black = '#000000',
  rmwcSecondary = '#FFFFFF',
  disabledMonth = '#DCE0E0',
  skyBlueLight = '#eff8ff',
  softBlue = '#afd5ee',
}

export const channel = new BroadcastChannel('my_channel');

